/* tailwind css setting*/
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "../tailwindStyle/pms.css";

.circle {
  animation: cycle 0.7s infinite linear;
}

/* .ant-list-split .ant-list-item {
  border-block-end: 1px solid unset !important;
} */

@keyframes cycle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MES-lifeF-0 {
  @apply !bg-white;
}
.MES-lifeF-1 {
  @apply !bg-blue-300;
}
.MES-lifeF-2 {
  @apply !bg-green-200;
}
.MES-lifeF-B {
  @apply !bg-green-200;
}
.MES-lifeF-3 {
  @apply !bg-sky-200;
}
.MES-lifeF-4 {
  @apply !bg-green-200;
}
.MES-lifeF-7 {
  @apply !bg-[#FFC000];
}
.MES-lifeF-8 {
  @apply !bg-pink-200;
}
.MES-lifeF-9 {
  @apply !bg-[#f87272];
}
.MES-lifeF-G {
  @apply !bg-[#00B050];
}
.MES-lifeF-T {
  @apply !bg-[#00B050];
}
/* lifeF WMS 用 */
/* 創建 */
.lifeF-0 {
  @apply bg-[#dfe7f9];
}
/* 執行中 */
.lifeF-1 {
  @apply bg-[#adddb6];
}
/* 提交中 */
.lifeF-2 {
  @apply bg-[#eec8c8];
}
/*  */
.lifeF-3 {
  @apply bg-[#eec8c8];
}
/*  */
.lifeF-T {
  @apply bg-transparent;
}

.ant-btn {
  @apply whitespace-normal break-words;
}

.pds-btn-blue-outline {
  @apply [&:not(:disabled)]:text-[#1677FF]
  [&:not(:disabled)]:hover:text-white
  [&:not(:disabled)]:border-[#1677FF] 
  [&:not(:disabled)]:bg-white
  [&:not(:disabled)]:hover:bg-[#1677FF]
  [&:not(:disabled)]:hover:border-[#1677FF];
}

.ant-radio-wrapper .ant-radio-disabled .ant-radio-inner::after {
  background-color: #1677ff;
}
/* 滾動條樣式 */
::-webkit-scrollbar-track-piece {
  background-color: #f8f8f8c2;
}
::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
::-webkit-scrollbar-thumb {
  background-color: #dddddd;
  background-clip: padding-box;
  min-height: 28px;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
  border-radius: 10px;
}

.ant-message-custom-content {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 23px !important;
}
.ant-message-notice-content {
  background-color: rgb(255, 250, 178) !important;
}

/* 更改所有字體 font-weight 600 */
.ant-form-item-label,
::placeholder,
.ant-select-selection-placeholder,
span {
  /* 老A先關掉，待討論 */
  /* font-weight: 600; */
  -webkit-font-smoothing: antialiased;
}
.ant-form-vertical .ant-form-item-label {
  padding: 0 !important;
}

.ant-upload {
  width: 100% !important;
}

/* .ant-btn-default:not(:disabled):hover {
  color: #fff;
} */
/* 水波紋效果 */
@keyframes statusProcessing {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(2.4);
    opacity: 0;
  }
}
/* group button  最後一個選項固定為紅色*/
.ant-radio-group > label:last-child > .ant-radio-checked > .ant-radio-inner,
.ant-radio-group > label:last-child > .ant-radio-disabled .ant-radio-inner::after {
  border-color: #ff0000;
  background-color: #ff0000;
}
.ant-radio-group > label:last-child > .ant-radio-checked::after {
  border: 1px solid #ff0000;
}

.ant-radio-group > label:last-child > .ant-radio-disabled .ant-radio-inner {
  background-color: rgba(0, 0, 0, 0.04);
  border-color: #d9d9d9;
  cursor: not-allowed;
}

/* 數位生產看板全屏背景色 */
.fullscreen.fullscreen-enabled {
  background: rgb(49, 49, 49);
  padding: 24px;
}

/* 子菜單高度 */
.ant-menu-submenu .ant-menu-item {
  height: 30px !important;
  line-height: 30px !important;
  /* border:1px solid pink */
}

.ant-select-selector:enabled,
.ant-input:enabled,
.ant-input-number-input:enabled:hover,
.ant-input-number-input:enabled:focus,
textarea {
  background-color: #ffffff !important;
}
