.customHeaderBG .ant-table-container table > thead > tr:first-child > * {
  background-color: rgb(237, 236, 236) !important;
}

.mainTable .ant-table-container > .ant-table-header > table > thead > tr:first-child > * {
  /* background-color: rgba(245, 245, 245, 0.55) !important; */
  background-color: rgba(102, 102, 102, 0.5) !important;
  /* background-color: rgba(245, 245, 245, 0.1) !important; */
}

.mainTable .ant-table-content .ant-table-thead .ant-table-cell::before {
  background-color: rgba(102, 102, 102, 0) !important;
}

.mainTable .ant-table-cell::before {
  background-color: rgba(102, 102, 102, 0) !important;
}

.subTable .ant-table-container::before,
.subTable .ant-spin-container .ant-table-content .ant-table-thead .ant-table-cell {
  background-color: rgb(237, 236, 236) !important;
}
